
            import React from 'react';
            const mdx = (name, props, ...children) => {
                if (name === 'inlineCode') { name = 'code'; }
                delete props?.parentName;
                return React.createElement(name, props, ...children);
            }
        
/* @jsxRuntime classic */
/* @jsx mdx */
import { Stack } from "@fluentui/react";
import Head from 'next/head';
import { ExternalLink } from '../components';
import { RouteStackProps } from "../utils";


const layoutProps = {
  
};
const MDXLayout = ({children}) => (
    <div style={{ height: '100%', padding: '0 16px', overflow: 'auto' }}>
        <Head>
            <title>WebADB</title>
        </Head>
        {children}
    </div>
)
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    {
      /*
      cspell: ignore cybojenix
      */
    }
    <ExternalLink href="https://github.com/AndroPlus-org/ya-webadb" mdxType="ExternalLink">ya-webadb</ExternalLink> では、ウェブブラウザから ADB プロトコルを使ってデバイスをコントロールできます。
    <p>{`WebUSB に対応した環境であれば、追加のドライバーインストールをすることなく簡単に接続できます。`}</p>
    <p>{`「追加」ボタンを押してどのデバイスに接続するか選び、「接続」を押してください。`}</p>
    <p>{`デバイス側で USB デバッグを許可するか聞かれるので、許可してください。`}</p>
    <h2>{`セキュリティに関する懸念`}</h2>
    <p>{`ウェブページから USB デバイス (特にスマートフォン) に直接アクセスすることは、`}<strong parentName="p">{`非常に危険です`}</strong>{`。Firefox の開発者は、`}<ExternalLink href="https://mozilla.github.io/standards-positions/#webusb" mdxType="ExternalLink">{`それが有害である`}</ExternalLink>{`と見なし、WebUSB 標準の実装を拒否したほどです。`}</p>
    <p>{`このページについては、以下の通り安全性を確保しています。`}</p>
    <ol>
      <li parentName="ol">{`ネイティブアプリとは異なり、ウェブアプリは確認なしにデバイスにアクセスすることはできません。ADB での接続確認ポップアップに加え、ブラウザはユーザーに許可を求め、ウェブアプリは選択されたデバイスにしかアクセスできません。`}</li>
      <li parentName="ol">{`このプロジェクトのすべてのソースコードは、`}<ExternalLink href="https://github.com/AndroPlus-org/ya-webadb" mdxType="ExternalLink">{`GitHub`}</ExternalLink>{` でオープンソース化されています。自分でレビューすることもできます（または、信頼できるコーディングに詳しい人を見つけてレビューしてもらうこともできます）。`}</li>
      <li parentName="ol">{`このサイトは、`}<ExternalLink href="https://github.com/AndroPlus-org/ya-webadb/blob/master/.github/workflows/nodejs.yml" spaceAfter mdxType="ExternalLink">{`GitHub Actions`}</ExternalLink>{` によってビルド・デプロイされ、あなたが見るものがソースコードと全く同じであることを保証しています。`}</li>
    </ol>
    <h2>{`互換性`}</h2>
    <p>{`現在 WebUSB API をサポートしているのは、Chromium ベースのブラウザ（Chrome、Microsoft Edge、Opera）のみです。前述したように、Firefox が実装する可能性は低いです。`}</p>
    <h2>{`よくある質問`}</h2>
    <h3>{`"Unable to claim interface" エラーが出る`}</h3>
    <p>{`1つの USB デバイスには、同時に1つのアプリからしかアクセスできません。以下を確認してください：`}</p>
    <ol>
      <li parentName="ol">{`公式 ADB サーバーを起動している (`}<inlineCode parentName="li">{`adb kill-server`}</inlineCode>{` で停止できます).`}</li>
      <li parentName="ol">{`他の Android 管理ツールを起動している`}</li>
      <li parentName="ol">{`他の WebADB ページで既に接続されている`}</li>
    </ol>
    <h3>{`ワイヤレス接続（ADB over WiFi）は可能ですか？`}</h3>
    <p>{`接続の橋渡しをするために、別途ソフトウェアが必要です。 `}<ExternalLink href="https://github.com/yume-chan/ya-webadb/discussions/245#discussioncomment-384030" mdxType="ExternalLink">{`こちらの議論`}</ExternalLink>{`を確認してください。`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;